import { assets } from "@koble/assets/assets";
import { Button, Flex, Typography } from "antd";
import { ButtonProps } from "antd/lib/button";

const GoogleButton = ({
  text,
  alt,
  ...props
}: { text: string; alt: string } & ButtonProps) => {
  return (
    <Button type="default" style={{ marginTop: 16 }} size="large" {...props}>
      <Flex align="center" justify="center">
        <img
          src={assets.GOOGLE_LOGO}
          alt={alt}
          style={{
            width: 20,
            marginRight: 8,
            imageRendering: "crisp-edges",
          }}
        />
        <Typography.Text style={{ fontSize: 13 }}>{text}</Typography.Text>
      </Flex>
    </Button>
  );
};

export default GoogleButton;
